import { gql } from '@apollo/client';

export const GET_VENDORS = gql`
  query GetVendors(
    $filters: [Filter!]!
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getFilteredVendors(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        id
        fleetVendorNumber
        vendorName
        uniqueEntityIdentifier
        fleetStatus
        vendorTypes {
          vendorTypeCode
        }
        dunsNumber
        vendorContacts {
          contactType
          city
          state {
            stateCode
          }
          country {
            isoCountryCode2
          }
          town
          province
          emailAddress
          firstName
          lastName
        }
      }
      count
      hasMore
    }
  }
`;
export const GET_VENDOR_LIST = gql`
  query getVendorList(
    $filters: [Filter!]!
    $subFilters: [Filter!]
    $order: OrderBy!
    $limit: Int!
    $offset: Int!
  ) {
    getVendorList(
      filters: $filters
      subFilters: $subFilters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        vendorNo: fleetVendorNumber
        vendorName
        city
        stateCode
        stateName
        phone: contactPhone
        email: emailAddress
        country: isoCountryCode2
        vendorTypes {
          type: vendorType {
            code: vendorTypeCode
            name: vendorTypeName
          }
        }
        vendorStatus {
          code: vendorStatusCode
          title: vendorStatusTitle
        }
        vendorTier {
          code: vendorTierCode
          title: vendorTierTitle
        }
      }
    }
  }
`;

export const GET_VENDOR_LIST_FILTERS = gql`
  query getVendorFilters {
    vendorTypeFilter: getCommonVendorCodesByType(type: "Vendor type") {
      value: code
      label: name
    }
    stateFilter: getStates {
      label: stateCode
      value: id
    }
    vendorStatusFilter: getCommonVendorCodesByType(type: "Vendor status") {
      label: name
      value: code
    }
  }
`;

export const DELETE_VENDOR = gql`
  mutation vendorDelete($fleet_vendor_no: String!) {
    vendorDelete(fleetVendorNumber: $fleet_vendor_no) {
      fleetVendorNumber
    }
  }
`;

export const SEARCH_VENDOR = gql`
  query vendorSearch($col: String!, $queryStr: String!, $limit: Float) {
    vendorSearch(col: $col, queryStr: $queryStr, limit: $limit) {
      fleetVendorNumber
      vendorName
      contactPhone
      city
      emailAddress
    }
  }
`;

export const GET_VENDOR_TYPE_AHEAD_OPTIONS = gql`
  query getVendorTypeAheadOptions($key: String!, $search: String!) {
    getVendorTypeAheadOptions(key: $key, search: $search)
  }
`;

export const CHANGE_VENDOR_FLEET_STATUS = gql`
  mutation toggleVendorFleetStatus($input: ToggleVendorFleetStatusInput!) {
    toggleVendorFleetStatus(fleetStatusToggleInput: $input) {
      id
      vendorName
      fleetStatus
      fleetStatusUpdatedOn
    }
  }
`;

export const GET_FILTERED_CONTRACT_HEADERS = gql`
  query GetFilteredContractHeaders($filters: [Filter!]!) {
    getFilteredContractHeaders(filters: $filters, limit: 9999) {
      count
      rows {
        contractHeaderId
        vendorId
      }
    }
  }
`;

export const SEND_FILTERED_CONTRACT_HEADER_NOTIFICATION = gql`
  query SendFilteredContractHeaderNotification(
    $filters: [Filter!]!
    $emailType: String!
  ) {
    sendFilteredContractHeaderNotification(
      filters: $filters
      emailType: $emailType
    )
  }
`;

export default {
  GET_VENDOR_TYPE_AHEAD_OPTIONS,
  GET_VENDOR_LIST,
  GET_VENDOR_LIST_FILTERS,
  SEARCH_VENDOR,
  GET_VENDORS,
  CHANGE_VENDOR_FLEET_STATUS,
};
