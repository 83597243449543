import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from '@gsa/afp-shared-ui-utils';

export * from './data-layer/comments';
export * from './data-layer/vehicle';

const httpLink = createHttpLink({
  uri: window.AFP_CONFIG.api_url,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // TODO : temp fix to allow unauthenticated to make API calls
  const token = await getToken().catch(() => {
    // Ignore the error
  });

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const publicLink = createHttpLink({
  uri: window.AFP_CONFIG.public_api_url,
});

const vmsLink = createHttpLink({
  uri: window.AFP_CONFIG.vms_api_url,
});

const marketplaceLink = createHttpLink({
  uri: window.AFP_CONFIG.marketplace_api_url,
});

const catalogLink = createHttpLink({
  uri: window.AFP_CONFIG.catalog_api_url,
});

/*
  Setting up the Apollo Client to use multiple links
  so that we can use the same client to make calls to
  both the public and private APIs.
  We need to set the context for each link to determine
  which link to use for each call. Since we don't want to load up shared-gateway
  with other product dependencies.
  useLazyQuery(GET_PROSPECT, {
    context: {
      clientName: 'public',
    },
    onError: () => {
      setInvalidState(true);
    },
  })
 */
const dataStore = new ApolloClient({
  link: ApolloLink.split(
    (operation) => {
      const { clientName } = operation.getContext();
      return clientName === 'vms';
    },
    authLink.concat(vmsLink),
    ApolloLink.split(
      (operation) => {
        const { clientName } = operation.getContext();
        return clientName === 'marketplace';
      },
      authLink.concat(marketplaceLink),
      ApolloLink.split(
        (operation) => {
          const { clientName } = operation.getContext();
          return clientName === 'catalog';
        },
        authLink.concat(catalogLink),
        ApolloLink.split(
          (operation) => {
            const { clientName } = operation.getContext();
            return clientName === 'public';
          },
          publicLink,
          authLink.concat(httpLink),
        ),
      ),
    ),
  ),
  cache: new InMemoryCache(),
});

export const CURRENT_USER = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      fullName
      countryCode
      addrline1
      addrline2
      city
      state
      postalCode
      phoneNumberCountryCode
      phoneNumber
      phoneNumberExt
      supervisorId
      behaviorRulesAcceptedAt
      supervisorUser {
        firstName
        lastName
        fullName
        email
      }
      customerAttrs {
        registeredAgencyCode
        registeredBureauCode
        registeredOfficeCode
        isContractor
      }
      vendorAttrs {
        fleetVendorNumber
        vendorName
      }
      internalAttrs {
        userId
        branch {
          id
          name
        }
        division {
          id
          name
        }
        zone {
          id
          name
        }
        managementCenter {
          id
          name
        }
        fieldOffice {
          id
          name
        }
      }
      userType {
        id
        name
      }
      abilities
      roles
      status {
        id
        name
      }
    }
  }
`;
export const GET_COUNTRIES = gql`
  query GetCountries {
    getCountries {
      id
      isoCountryCode2
      isoCountryCode3
      countryName
    }
  }
`;
export const GET_STATES = gql`
  query GetStates {
    getStates {
      id
      stateCode
      stateName
      isoCountryCode2
    }
  }
`;

export const GET_MAKE_NAMES = gql`
  query GetMakeNames {
    getMakeNames {
      makeName
      makeCode
    }
  }
`;

export const GET_CATALOGS_FOR_CATEGORY = gql`
  query GetCatalogsForCategory($category: String!) {
    getCatalogsForCategory(category: $category) {
      id
      code
      displayText
      description
    }
  }
`;

export const GET_PHONE_COUNTRY_CODES = gql`
  query GetPhoneCountryCodes {
    getPhoneCountryCodes {
      code
      displayText
      description
    }
  }
`;

export const GET_AGENCIES = gql`
  query GetAgencies($order: OrderBy) {
    getAgencies(order: $order) {
      name: prefixedName
      id
    }
  }
`;
export const GET_BUREAUS = gql`
  query GetBureaus($agencyCode: String, $order: OrderBy) {
    getBureaus(agencyCode: $agencyCode, order: $order) {
      name: prefixedName
      agencyCode
      id
    }
  }
`;

export const GET_AGENCIES_BY_PERMISSION = gql`
  query GetAgenciesByPermission(
    $operation: String!
    $subject: String!
    $order: OrderBy
    $feature: String
  ) {
    getAgenciesByPermission(
      operation: $operation
      subject: $subject
      order: $order
      feature: $feature
    ) {
      name: prefixedName
      id
    }
  }
`;

export const GET_BUREAUS_BY_PERMISSION = gql`
  query GetBureausByPermission(
    $agencyCode: String!
    $operation: String!
    $subject: String!
    $order: OrderBy
    $feature: String
  ) {
    getBureausByPermission(
      agencyCode: $agencyCode
      operation: $operation
      subject: $subject
      order: $order
      feature: $feature
    ) {
      name: prefixedName
      id
    }
  }
`;

export const GET_OFFICES_BY_PERMISSION = gql`
  query GetOfficesByPermission(
    $agencyCode: String!
    $bureauCode: String!
    $subject: String!
    $operation: String!
    $order: OrderBy
    $feature: String
  ) {
    getOfficesByPermission(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      subject: $subject
      operation: $operation
      order: $order
      feature: $feature
    ) {
      officeCode
      officeName: prefixedName
    }
  }
`;

export const GET_OFFICES = gql`
  query GetOfficesNext(
    $bureauCode: String
    $agencyCode: String
    $order: OrderBy
  ) {
    getOfficesNext(
      bureauCode: $bureauCode
      agencyCode: $agencyCode
      order: $order
    ) {
      officeCode
      officeName: prefixedName
    }
  }
`;
export const CURRENT_USER_NOTIFICIATIONS = gql`
  query GetNotifications(
    $filters: [Filter!]!
    $order: OrderBy!
    $limit: Int!
    $offset: Int!
  ) {
    getNotifications(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        id
        status
        message
        linkDetail
        linkedEntity
        createdAt
      }
      count
      hasMore
    }
  }
`;

export const USER_TASKS = gql`
  query GetTasks(
    $filters: [Filter!]!
    $order: OrderBy!
    $offset: Int
    $limit: Int
  ) {
    getTasks(filters: $filters, order: $order, offset: $offset, limit: $limit) {
      rows {
        id
        taskID
        taskType {
          taskType
          taskTypeName
        }
        title
        status
        metaData
        description
        dueDate
        updatedAt
      }
      count
      hasMore
    }
  }
`;

export const FETCH_TYPEAHEAD = gql`
  query GetTypeAheads(
    $model: String!
    $field: String!
    $order: OrderBy!
    $filters: [Filter!]!
  ) {
    getTypeAheads(
      model: $model
      field: $field
      order: $order
      filters: $filters
    ) {
      typeAheads {
        field
        values
      }
    }
  }
`;

export const TASK_DETAIL = gql`
  query GetTaskById($id: Float!) {
    getTaskById(id: $id) {
      id
      taskID
      taskHash
      title
      description
      status
      linkedEntities
      metaData
      taskType
      assignedUser
      createdAt
      createdByUser
      updatedAt
      dueDate
      updatedByUser
      taskRecall {
        recallNhtsaID
        recallStatusCode
      }
    }
  }
`;

export const TOGGLE_TASK_STATUS = gql`
  mutation ToggleTaskStatus($id: Float!, $status: String!) {
    toggleTaskStatus(id: $id, status: $status) {
      title
      status
      updatedAt
      updatedByUser
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomerByID($customerID: String!) {
    getCustomerByID(customerID: $customerID) {
      customerID
      firstName
      lastName
      email
      phone
    }
  }
`;

export const GET_CUSTOMER_SUPERVISORS = gql`
  query getCustomerSupervisors(
    $agencyCode: String!
    $bureauCode: String!
    $officeCode: String!
  ) {
    getCustomerSupervisors(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      officeCode: $officeCode
    ) {
      id
      email
      firstName
      lastName
      fullName
    }
  }
`;

export const GET_INTERNAL_SUPERVISORS = gql`
  query getInternalSupervisors {
    id @client
  }
`;

export const UPDATE_MY_USER_PROFILE = gql`
  mutation updateMyUserProfile($userProfileInput: UserProfileInput!) {
    updateMyUserProfile(userProfileInput: $userProfileInput)
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($userProfileInput: UserProfileInput!) {
    updateUserProfile(userProfileInput: $userProfileInput)
  }
`;

export const REGISTER_PROSPECT = gql`
  mutation registerProspect(
    $firstName: String!
    $lastName: String!
    $email: String!
    $userType: Float!
  ) {
    registerProspect(
      firstName: $firstName
      lastName: $lastName
      email: $email
      userType: $userType
    ) {
      state
    }
  }
`;

export const GET_PROSPECT = gql`
  query GetProspectByID($code: String!) {
    getProspectById(code: $code) {
      firstName
      lastName
      email
    }
  }
`;

export const ACTIVATE_PROSPECT = gql`
  mutation ActivateProspect($code: String!) {
    activateProspect(code: $code)
  }
`;

export const REACTIVATE_PROSPECT = gql`
  mutation ReactivateProspect($code: String!) {
    reactivateProspect(code: $code)
  }
`;

export const GET_USER_TYPES = gql`
  query GetUserTypes {
    getUserTypes {
      id
      name
      desc
    }
  }
`;

export const SET_CREDENTIALS = gql`
  mutation SetCredentials(
    $code: String!
    $password: String!
    $recoveryQuestion: String!
    $recoveryAnswer: String!
  ) {
    setCredentials(
      code: $code
      password: $password
      recoveryQuestion: $recoveryQuestion
      recoveryAnswer: $recoveryAnswer
    )
  }
`;

export const REGISTER_USER_BY_INVITATION = gql`
  mutation RegisterByInvitation($code: String!) {
    registerByInvitation(code: $code) {
      state
      token
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles(
    $limit: Float
    $offset: Float
    $order: OrderBy
    $filters: [Filter!]!
  ) {
    getRoles(limit: $limit, offset: $offset, order: $order, filters: $filters) {
      rows {
        id
        name
        isInactive
        userType {
          name
        }
      }
      count
    }
  }
`;

export const GET_ROLE_EXTRA_INFO = gql`
  query getRole($roleId: String!) {
    getRole(id: $roleId) {
      desc
      assignedUsers
      roleParentNames
    }
  }
`;

export const REACTIVATE_ROLE = gql`
  mutation reactivateRole($id: String!) {
    reactivateRole(id: $id) {
      name
    }
  }
`;

export const DEACTIVATE_ROLE = gql`
  mutation deactivateRole($id: String!) {
    deactivateRole(id: $id) {
      name
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($id: String!) {
    getUserById(id: $id) {
      id
      fullName
      firstName
      lastName
      createdAt
      lastLogin
      email
      supervisorId
      supervisorUser {
        firstName
        lastName
        fullName
        email
      }
      vendorAttrs {
        fleetVendorNumber
      }
      customerAttrs {
        userId
        registeredAgencyCode
        registeredBureauCode
        registeredOfficeCode
        isContractor
      }
      internalAttrs {
        userId
        branch {
          id
          name
        }
        division {
          id
          name
        }
        zone {
          id
          name
        }
        managementCenter {
          id
          name
        }
        fieldOffice {
          id
          name
        }
        warrantLevel
      }
      countryCode
      addrline1
      addrline2
      addrline3
      state
      city
      postalCode
      phoneNumberCountryCode
      phoneNumber
      phoneNumberExt
      updatedStatusAt
      updatedStatusByUser {
        fullName
      }
      userType {
        id
        name
      }
      status {
        id
        name
      }
    }
  }
`;
export const GET_ZONES_CENTERS = gql`
  query {
    getZones {
      id
      name
      dataSource
      regions {
        id
        name
      }
      centers {
        id
        sortId
        name
        stateCode
        fieldOffices {
          id
          name
        }
      }
    }
  }
`;
export const GET_DIVISIONS_BRANCHES = gql`
  query {
    getDivisions {
      id
      name
      branches {
        id
        name
      }
    }
  }
`;
export const GET_TIME_ZONES = gql`
  query {
    getTimeZones {
      id
      name
      standardCode
      internalCode
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers(
    $order: OrderBy
    $offset: Float
    $limit: Float
    $filters: [Filter!]!
  ) {
    getUsers(order: $order, offset: $offset, limit: $limit, filters: $filters) {
      rows {
        id
        firstName
        lastName
        email
        lastCertified
        recertificationStatus
        userType {
          id
          name
        }
        status {
          name
          id
        }
      }
      count
    }
  }
`;

export const GET_EXPORT_USERS = gql`
  query GetUserExport(
    $filters: [Filter!]!
    $order: OrderBy
    $offset: Float
    $limit: Float
  ) {
    getUserExport(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    )
  }
`;

export const GET_USER_INVITATIONS = gql`
  query GetUserInvitations(
    $order: OrderBy
    $offset: Float
    $limit: Float
    $filters: [Filter!]!
  ) {
    getUserInvitations(
      order: $order
      offset: $offset
      limit: $limit
      filters: $filters
    ) {
      rows {
        id
        firstName
        lastName
        email
        status
        createdAt
        cancelledAt
        completedAt
        userType {
          name
        }
        manager {
          firstName
          lastName
          email
        }
        vendorInvitationAttrs {
          fleetVendorNo
        }
      }
      count
    }
  }
`;

export const GET_AGENCY = gql`
  query GetAgency($id: String!) {
    getAgency(id: $id) {
      name: prefixedName
    }
  }
`;

export const GET_USER_STATUSES = gql`
  query GetUserStatuses($order: OrderBy) {
    getUserStatuses(order: $order) {
      id
      name
    }
  }
`;

export const GET_BUREAU = gql`
  query GetBureau($id: String!, $agencyCode: String!) {
    getBureau(id: $id, agencyCode: $agencyCode) {
      name: prefixedName
    }
  }
`;

export const GET_ACTIVE_ROLES = gql`
  query GetActiveRoles($order: OrderBy) {
    getActiveRoles(order: $order) {
      id
      name
    }
  }
`;

export const GET_OFFICE = gql`
  query GetOfficeNext(
    $agencyCode: String!
    $bureauCode: String!
    $officeCode: String!
  ) {
    getOfficeNext(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      officeCode: $officeCode
    ) {
      officeName: prefixedName
    }
  }
`;

export const GET_USERS_BY_IDS = gql`
  query GetUsersByIds($ids: [String!]!) {
    getUsersByIds(ids: $ids) {
      id
      fullName
      lastCertified
      recertificationStatus
      recertificationDueDate
      recertifiable
      userType {
        id
        name
      }
    }
  }
`;

export const GET_USER_WITH_SCOP_REFERENCE = gql`
  query GetUserWithScopRefById($id: String!) {
    getUserById(id: $id) {
      id
      userRoleList {
        id
        role {
          name
        }
        scope {
          id
          name: prefixedName
          scopeReference
        }
      }
    }
  }
`;

export const GET_BIDDER_PROFILE_INFO = gql`
  query GetBidderProfileInfo($userId: String!) {
    getBidderProfileInfo(userId: $userId) {
      userId
      physicalAddrLine1
      physicalAddrLine2
      physicalAddrLine3
      physicalCity
      physicalStateCode
      physicalZipCode
      mailingAddrLine1
      mailingAddrLine2
      mailingCity
      mailingStateCode
      mailingZipCode
      countryCode
      bidderNumber
      decryptedPassportNumber
      passportCountry
      company_information {
        companyName
        phoneNumber
        physicalAddrLine1
        physicalAddrLine2
        physicalAddrLine3
        physicalCity
        physicalStateCode
        physicalZipCode
        mailingAddrLine1
        mailingAddrLine2
        mailingCity
        mailingStateCode
        mailingZipCode
        countryCode
      }
    }
  }
`;

export const GET_VENDORS_BY_FLEET_VENDOR_NUMBER = gql`
  query ($fleetVendorNumber: String!) {
    getVendorDetailByFleetVendorNumber(fleetVendorNumber: $fleetVendorNumber) {
      vendorName
      vendorTypes {
        vendorTypeCode
        commonCode {
          name
        }
      }
      vendorContacts {
        contactType
        address1
        address2
        city
        state {
          stateCode
        }
        postalCode
      }
    }
  }
`;

export const GET_VENDORS_BY_FLEET_VENDOR_NUMBERS = gql`
  query GetVendorsByFVNs($fleetVendorNumbers: [String!]!) {
    getVendorsByFleetVendorNumbers(fleetVendorNumbers: $fleetVendorNumbers) {
      id
      vendorName
      vendorTypes {
        vendorTypeCode
        commonCode {
          name
        }
      }
      vendorContacts {
        contactType
        address1
        address2
        address3
        city
        state {
          stateCode
        }
        province
        town
      }
    }
  }
`;

export const GET_ASSIGNABLE_ROLES = gql`
  query GetAssignableRoles($id: String!) {
    getAssignableRoles(id: $id) {
      id
      name
    }
  }
`;

export const RECERTIFY_USERS = gql`
  mutation RecertifyUsers($ids: [String!]!) {
    recertifyUsers(ids: $ids)
  }
`;

export const ASSIGN_ROLE = gql`
  mutation AssignRole(
    $userId: String!
    $roleId: Float!
    $scopeId: Float!
    $userTypeId: Float!
  ) {
    assignRole(
      userId: $userId
      roleId: $roleId
      scopeId: $scopeId
      userTypeId: $userTypeId
    ) {
      createdAt
      role {
        name
      }
      scope {
        name: prefixedName
        parentScope {
          name: prefixedName
          parentScope {
            name: prefixedName
          }
        }
      }
    }
  }
`;

export const UNASSIGN_ROLE = gql`
  mutation UnassignRole($userRoleId: Float!) {
    unassignRole(userRoleId: $userRoleId)
  }
`;

export const DENY_USER_ACCESS = gql`
  mutation denyUserAccess($id: String!) {
    denyUserAccess(id: $id)
  }
`;

export const REQUEST_REACTIVATION = gql`
  mutation requestReactivation {
    requestReactivation
  }
`;

export const APPROVE_USER_ACCESS = gql`
  mutation approveUserAccess($id: String!) {
    approveUserAccess(id: $id)
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($id: String!, $status: Float!) {
    updateUserStatus(id: $id, status: $status)
  }
`;

export const GET_ASSIGNABLE_SCOPES = gql`
  query getAssignableScopes(
    $userTypeId: Float!
    $level: Float!
    $roleId: Float!
    $parentId: Float
    $filters: [Filter!]
  ) {
    getAssignableScopes(
      userTypeId: $userTypeId
      level: $level
      roleId: $roleId
      parentId: $parentId
      filters: $filters
    ) {
      scopes {
        id
        name: prefixedName
        level
        scopeReference
      }
      required
      visible
    }
  }
`;

export const GET_SCOPE_BY_ROLE = gql`
  query getScopesByRole($roleId: Float!) {
    getScopesByRole(roleId: $roleId) {
      id
      name
      level
      parentScope {
        id
        name
        level
        parentScope {
          id
          name
          level
          parentScope {
            id
            name
            level
          }
        }
      }
    }
  }
`;

export const GET_VENDORS_BASIC = gql`
  query getVendors {
    getVendors {
      fleetVendorNumber
      vendorName
    }
  }
`;

export const GET_VENDOR_TYPE_AHEAD_WITH_FVN = gql`
  query getVendorTypeAheadWithFvn($search: String!) {
    getVendorTypeAheadWithFvn(search: $search)
  }
`;

export const GET_VENDORS_BY_PERMISSION = gql`
  query getVendorsByPermission(
    $operation: String!
    $subject: String!
    $filters: [Filter!]
  ) {
    getVendorsByPermission(
      operation: $operation
      subject: $subject
      filters: $filters
    ) {
      id
      fleetVendorNumber
      vendorName
      vendorContacts {
        contactType
        city
        state {
          stateCode
        }
        postalCode
      }
      vendorTypes {
        vendorTypeCode
        commonCode {
          name
        }
      }
      fleetStatus
      isConfirmed
    }
  }
`;

export const GET_VENDOR = gql`
  query getVendor($fleetVendorNumber: String!) {
    getVendor(fleetVendorNumber: $fleetVendorNumber) {
      vendorNumber: fleetVendorNumber
      vendorName
      address1
      address2
      city
      stateCode
      postalCode
      country: isoCountryCode2
      phone: contactPhone
      extension: contactPhoneExtension
      vendorTypes {
        vendorTypeCode
        vendorType {
          vendorTypeName
        }
      }
      vendorStatus {
        vendorStatusTitle
      }
    }
  }
`;

export const CREATE_USER_INVITATION = gql`
  mutation addUserInvitation($userInvitationInput: UserInvitationInput!) {
    addUserInvitation(userInvitationInput: $userInvitationInput) {
      id
      fullName
    }
  }
`;

export const GET_VENDOR_MANAGERS = gql`
  query getVendorManagers($fleetVendorNumber: String!) {
    getVendorManagers(fleetVendorNumber: $fleetVendorNumber) {
      id
      email
      firstName
      lastName
      fullName
    }
  }
`;

export const ACCEPT_BEHAVIOR_RULES = gql`
  mutation {
    acceptBehaviorRules
  }
`;

export const CANCEL_USER_INVITATION = gql`
  mutation cancelUserInvitation($invitationId: Float!) {
    cancelUserInvitation(invitationId: $invitationId)
  }
`;

export const GET_SHARED_ENABLED_FEATURES = gql`
  query getSharedEnabledFeatures {
    getSharedEnabledFeatures
  }
`;

export const GET_USER_ROLE_SCOPES_BY_USER_ID = gql`
  query GetUserRoleScopes(
    $userId: String!
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getUserRoleScopes(
      userId: $userId
      limit: $limit
      offset: $offset
      order: $order
    ) {
      count
      hasMore
      rows {
        id
        roleId
        role {
          name
        }
        scope {
          id
          name: prefixedName
          isCustomerAccount
          parentScope {
            id
            name: prefixedName
            isCustomerAccount
            parentScope {
              id
              name: prefixedName
              isCustomerAccount
            }
          }
        }
      }
    }
  }
`;

export default dataStore;
