import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { Unauthorized, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from '../../widgets/breadcrumbs';
import WelcomeMessage from '../../widgets/welcome-message';
import VendorListingProvider from './vendor-listing-provider';
import VendorListingTable from './vendor-listing-table';
import VendorListingAlert from './vendor-listing-alert';

const VendorListing = () => {
  const { currentUser } = useCurrentUser();
  if (!currentUser) {
    return null;
  }
  if (currentUser?.userType?.id === '2') {
    return <Unauthorized />;
  }
  return (
    <div className="grid-col sales-listing-page">
      <VendorListingProvider>
        <>
          <Breadcrumbs
            path={[
              {
                location: `${window.AFP_CONFIG.appURLs.home}/home`,
                label: 'Home',
              },
            ]}
            current="Vendors"
          />
          <PageTitle title="Vendors" />
          <VendorListingAlert />
          <WelcomeMessage message="" />
          <VendorListingTable />
        </>
      </VendorListingProvider>
    </div>
  );
};

export default VendorListing;
