import { Alert } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const ALERT_TYPES = ['success', 'error', 'info', 'warning'];

const BannerMessage = ({ type, message, onClose, showClose, ...props }) => {
  if (!ALERT_TYPES.includes(type) || !message) return null;

  React.useEffect(() => {
    if (message?.length) window.scrollTo(0, 0);
  }, [message]);

  return (
    <Alert slim showClose={showClose} onClose={onClose} type={type} {...props}>
      {/* Using ReactHtmlParser as it is safer than using the native dangerouslySetInnerHTML */}
      {ReactHtmlParser(message)}
    </Alert>
  );
};

BannerMessage.defaultProps = {
  type: null,
  message: null,
  showClose: true,
  onClose: () => {
    // logic here...
  },
};

BannerMessage.propTypes = {
  type: PropTypes.oneOf(ALERT_TYPES),
  message: PropTypes.string,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default BannerMessage;
