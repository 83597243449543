/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
  StatusBadge,
  FilterTableFrame,
  EmptyState,
  Spinner,
} from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import useDidMountEffect from '../../hooks/use-did-mount';
import { useVendorListing } from './vendor-listing-provider';
import VendorListingFilter from './component/Filter/vendor-listing-filter';
import {
  VENDOR_FLEET_STATUS_BADGE_VARIANT_MAP,
  FLEET_STATUS_ACTION_MAP,
  FLEET_STATUS_UPDATED_BY,
} from '../constants';
import VendorListingTableSubRow from './component/vendor-listing-table-sub-row';
import { isFeatureEnabled } from '../../utilities/feature-toggle';

const getActions = (original) => {
  const actions = [];
  const editAction = {
    icon: 'edit',
    label: 'Edit',
  };
  const deactivate = {
    icon: 'arrow_downward',
    label: 'Deactivate',
  };
  const reactivate = {
    icon: 'check',
    label: 'Reactivate',
  };

  switch (original.fleetStatus) {
    case 'Draft':
      actions.push(editAction);
      actions.push(deactivate);
      break;
    case 'Active':
      actions.push(editAction);
      actions.push(deactivate);
      break;
    case 'Inactive':
      actions.push(editAction);
      actions.push(reactivate);
      break;
    default:
      actions.push(editAction);
  }

  return actions;
};

const VendorListingTable = () => {
  const ability = useAppAbility();

  const { currentUser } = useCurrentUser();
  const history = useHistory();
  const initFilters = {
    conditions: [
      {
        key: '',
        operator: '',
        value: '',
      },
    ],
    operator: '$and',
  };
  const [filters, setFilters] = useState(initFilters);
  const [order, setOrder] = useState([['vendorName', 'ASC']]);
  const [showFilters] = useState(true);

  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const {
    vendors,
    getVendors,
    changeVendorFleetStatus,
    isVendroDataLoading,
    setMessage,
    getFilteredContractHeaders,
    filteredContractHeaders,
  } = useVendorListing();

  const tableRef = React.createRef();

  const getData = () => {
    const { limit, offset } = paginationState;

    getVendors({
      variables: { filters, offset, limit, order },
    });
  };

  const displayLocValue = (city, state) => {
    if (!city && !state) {
      return <>&ndash;</>;
    }

    return `${city || '-'}, ${state || '-'}`;
  };

  // the data comming from the backend needs to be manupulated to resolve the table
  // accessor issue while using the same accessor. A new property called physicalAddress
  // is injected per each row and referred as accessor for the AFP table
  let physicalAddress;
  vendors?.rows.forEach((e, index) => {
    physicalAddress = e.vendorContacts?.filter(
      (contact) => contact.contactType === 'VendorPhysicalContact',
    );
    vendors.rows[index].physicalAddress = physicalAddress?.length
      ? physicalAddress[0]
      : {};
  });

  useEffect(() => {
    getData();
  }, []);

  useDidMountEffect(() => {
    getData();
  }, [filters, order, paginationState]);

  useEffect(() => {
    if (!vendors.rows) return;

    getFilteredContractHeaders({
      variables: {
        filters: [
          {
            conditions: [
              {
                operator: '$in',
                key: 'vendorId',
                value: vendors.rows.map((vendor) => vendor.id),
              },
              {
                operator: '$exact',
                key: 'publishedInFleet',
                value: '1',
              },
            ],
            operator: '$and',
          },
        ],
      },
    });
  }, [vendors]);

  const handleSelectedAction = async (e, row) => {
    if (e === 'Deactivate') {
      const rowContractHeader = filteredContractHeaders?.rows?.find(
        (contractHeader) => {
          return contractHeader.vendorId === row.original.id;
        },
      );

      if (rowContractHeader) {
        setMessage(
          'error',
          `There are active contracts associated with the selected vehicle supplier ${row.original.vendorName}. The contracts need to be unpublished before you can proceed.`,
        );
        return;
      }
    }

    if (e === 'Deactivate' || e === 'Reactivate') {
      changeVendorFleetStatus({
        variables: {
          input: {
            id: row.original.id,
            fleetStatus: FLEET_STATUS_ACTION_MAP[e],
            fleetStatusUpdatedBy: FLEET_STATUS_UPDATED_BY.Admin,
          },
        },
      });
    }
    if (e === 'Edit') history.push(`/vendor/edit/${row.original.id}`);
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Name',
        accessor: 'vendorName',
        sortable: true,
        Cell: ({ row: { original } }) => {
          return (
            <a href={`/vendor/details/${original.id}`}>{original.vendorName}</a>
          );
        },
      },
      {
        Header: 'Country',
        accessor: 'vendorContacts',
        sortable: false,
        Cell: ({ value }) => {
          const vendorPhysicalContact = value?.find(
            (contact) => contact?.contactType === 'VendorPhysicalContact',
          );
          return (
            vendorPhysicalContact?.country?.isoCountryCode2 || <>&ndash;</>
          );
        },
      },
      {
        Header: 'Location',
        accessor: 'physicalAddress',
        sortable: false,
        Cell: ({ value }) => {
          let stateProvince;
          let cityTown;
          if (value?.country?.isoCountryCode2 === 'US') {
            stateProvince = value?.state?.stateCode;
            cityTown = value.city;
          } else {
            stateProvince = value?.province;
            cityTown = value.town;
          }
          return displayLocValue(cityTown, stateProvince);
        },
      },
      {
        Header: 'Fleet Status',
        accessor: 'fleetStatus',
        sortable: true,
        Cell: ({ value }) =>
          value ? (
            <StatusBadge variant={VENDOR_FLEET_STATUS_BADGE_VARIANT_MAP[value]}>
              {value}
            </StatusBadge>
          ) : (
            <>&ndash;</>
          ),
      },
    ];

    // const allowedActions = actionList;
    // replace actionList with the filtered list when permissions are set up
    //  actionList.filter((a) =>
    //   ability.can(a.operation, 'Vendor'),
    // );

    const hasNoAccessToActions = () => {
      const notAllowedRoles = ['2501', '2502', '2503', '2504'];
      /* eslint-disable no-restricted-syntax */
      for (const userRole of currentUser?.roles) {
        if (notAllowedRoles.includes(userRole.id)) {
          return false; // User does not have access
        }
      }
      return true; // User have access
    };

    columnList.push({
      Header: 'Actions',
      sortable: false,
      headerClassName: 'cell-center',
      cellClassName: 'cell-center',
      Cell: (props) => (
        <AFPTableRowAction
          actions={hasNoAccessToActions() ? getActions(props.row.original) : []}
          // eslint-disable-next-line react/prop-types
          onSelectAction={(evt) => {
            handleSelectedAction(evt, props.row);
          }}
          {...props}
        />
      ),
    });

    return columnList;
  }, [filteredContractHeaders]);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return <VendorListingTableSubRow row={original} />;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const handleSort = (options) => {
    const feilds = options?.split(' ');
    if (feilds && feilds.length >= 2) {
      // eslint-disable-next-line
      feilds[0] === '`vendorContacts`' || feilds[0] === '`physicalAddress`'
        ? setOrder([
            'VendorModel',
            // 'vendorContacts',
            // 'isoCountryCode2',
            feilds[1],
          ])
        : setOrder([[feilds[0]?.replace(/`/g, ''), feilds[1]]]);
    }
  };

  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      {tableProps.isVendroDataLoading && (
        <Spinner data-testid="vendor-listing-table-spinner" size="small" />
      )}
      {tableProps.data?.length && !tableProps.isVendroDataLoading ? (
        <Pagination {...paginationProps} />
      ) : null}
      {tableProps.data === null ||
        (tableProps.data?.length === 0 && !tableProps.isVendroDataLoading && (
          <div className="text-center margin-top-neg-2 height-full">
            <EmptyState
              hasBackground
              containerStyles="padding-top-8 height-full"
              topText={
                <p
                  aria-label="There are no Vendors."
                  // eslint-disable-next-line
                  tabIndex={0}
                >
                  <strong>There are no Vendors.</strong>
                </p>
              }
            />
          </div>
        ))}
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, Button, VendorListingFilter, TableWrapper),
    [columns],
  );

  return (
    <>
      {/* <Can I="create" a="Vendor"> -- enable this when permissions are set up */}
      <FTF
        upRightProps={{
          label: 'Create Vendor',
          className: 'float-right',
          leftIcon: { name: 'add' },
          onClick: () => window.open('/vendor/create'),
          disabled:
            !isFeatureEnabled('vendors-create') ||
            !ability.can('create', 'Vendor'),
        }}
        lowLeftProps={{
          currentFilters: filters,
          setFilters,
        }}
        lowRightProps={{
          tableProps: {
            testId: 'vendor-listing-table',
            expandable: true,
            fullWidth: true,
            ref: tableRef,
            columns,
            data: vendors.rows || [],
            renderRowSubComponent,
            defaultSort: order,
            onSort: handleSort,
            isVendroDataLoading,
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: vendors?.count,
          },
        }}
        filterToggle={showFilters}
      />
    </>
  );
};

export default VendorListingTable;
